/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
$brand-color: #009444;

html, body {
  font-family: 'Roboto', sans-serif;
}
.row > *{
  padding: 0;
}
.row{
  margin: 0 !important;
  padding: 0 !important;
}
.col-fixed-head{
  height: 68px;
}
.h-100vh{
   height: 100vh;
}

$brand-color: #009444;
.box-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2)
}

.brand-color {
  color: $brand-color;
}
.btn-success{
  background-color: $brand-color !important;

}
 .btn-success:hover{
  background-color: #ffffff !important;
  color: $brand-color !important;
}
// loader

.loading-div{
  text-align: center;
  margin-top: 100px;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring {
    height: 100vh;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid rgb(0, 91, 43);
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: rgb(1, 83, 35) transparent transparent transparent;
    }
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}
// component

.left-section{
  margin-top: 3em;
}

.scroll {
  display: block;
  border: 0px solid red;

  margin-right: 5px;
  width: 100%;
  height: 50vh;
  overflow-y: scroll;
}

// .shepherd-footer {
//   align-items: center;
//   border-bottom-left-radius: 5px;
//   border-bottom-right-radius: 5px;
//   display: flex;
//   justify-content: space-between;
//   padding: 0 .75rem .75rem;

//   .shepherd-button:last-child {
//     margin-right: 0;
//   }

//   .shepherd-progress {
//     font-size: .8rem;
//   }
// }

// .shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
//   background-color: #4f7600 !important;
// }
.shepherd-has-title .shepherd-content .shepherd-header {
background-color: #00c159 !important;

}
.shepherd-element {
  border-radius: 9px !important;
}
.shepherd-has-title .shepherd-content .shepherd-header  .shepherd-title{
  color: #FFFFFF !important;
  }
.shepherd-element {
  border: solid 4px #16202D;
  border: none !important;
  box-shadow: 2px 4px 6px gray !important;
}
.shepherd-has-title .shepherd-content .shepherd-footer  .shepherd-button{
  background-color: #00c159 !important;
  color: #FFFFFF !important;
  }

  .shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
    background-color: #FFFFFF !important;
  }


.form-check-input:checked {
  background-color: $brand-color;
  border-color: $brand-color;
}


.shepherd-text{
  white-space: pre-wrap
}


.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow {
  top: -11px !important;
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before {
  border-left: solid #00c159 2px;
  border-top: solid #00c159 2px;
  border-right:  none !important;
  bottom: -3px
}

.shepherd-element[data-popper-placement^=top]>.shepherd-arrow {
  bottom: -11px !important;
}

.shepherd-element.shepherd-has-title[data-popper-placement^=top]>.shepherd-arrow:before {
  border-bottom: solid #00c159 2px;
  border-right: solid #00c159 2px;
  border-left: none;
  border-top: none;
  bottom: 2px
}

.shepherd-element[data-popper-placement^=right]>.shepherd-arrow {
  left: -11px !important;
}
.shepherd-element.shepherd-has-title[data-popper-placement^=right]>.shepherd-arrow:before {
  border-left: solid #00c159 2px;
  border-bottom: solid #00c159 2px;
  border-right: none !important;
  border-top: none !important;
  left: 1px;
}


.shepherd-arrow:before {
  content: "";
  border-right: solid #00c159 2px;
  border-top: solid #00c159 2px;
  right: -2.5px;
  bottom: -3px;
}

@media (min-width: 992px){
  .modal {
      max-width: 100% !important;
  }}

  @media (min-width: 576px){
  .modal-dialog {
      max-width: 550px !important;
      margin: 1.75rem auto;
  }}
